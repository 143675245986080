import React, { useEffect, useState } from "react";
import { Page } from "../components/Page";
import { Title } from "../components/Title";
import { Content } from "../components/Content";
import { Subtitle } from "../components/Subtitle";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

const format = (date) =>
  new Date(date.replace(" ", "T").concat("Z")).toLocaleString("fi-FI", {
    timeZone: "Europe/Helsinki",
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

const useWarnings = () => {
  const [warnings, setWarnings] = useState({});

  useEffect(() => {
    const url =
      "https://liukastumisvaroitus-api.beze.io/api/v1/warnings?order=created_at:desc";

    window
      .fetch(url)
      .then((response) => response.json())
      .then((warnings) => {
        setWarnings(
          warnings.reduce((accumulator, warning) => {
            const createdAtString = warning.created_at
              .replace(" ", "T")
              .concat("Z");

            const createdAt = new Date(createdAtString);
            const winterSeasonStart = new Date(createdAtString);

            winterSeasonStart.setMonth(6, 1);

            const createdAtYear = Number(warning.created_at.slice(0, 4));

            const year =
              createdAt > winterSeasonStart ? createdAtYear : createdAtYear - 1;

            if (!accumulator[year]) {
              accumulator[year] = [];
            }

            accumulator[year].push(warning);

            return accumulator;
          }, {})
        );
      });
  }, []);

  return warnings;
};

const IndexPage = () => {
  const warnings = useWarnings();

  return (
    <Page page="Varoitukset">
      <Row>
        <Col>
          <Title>Varoitukset</Title>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Subtitle>Varoitettavien lukumäärät</Subtitle>

          <Content>Päivitetty 04.09.2023.</Content>

          <Table striped bordered>
            <thead>
              <tr>
                <th>Kaupunki</th>
                <th>Määrä</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Lahti</td>
                <td>3120</td>
              </tr>
              <tr>
                <td>Helsinki</td>
                <td>3658</td>
              </tr>
              <tr>
                <td>Oulu</td>
                <td>5094</td>
              </tr>
              <tr>
                <td>Kuopio</td>
                <td>1995</td>
              </tr>
            </tbody>
          </Table>
        </Col>

        <Col md={6}>
          <Subtitle>Varoitusten ajankohdat ja kaupungit</Subtitle>

          <Tabs>
            {Object.keys(warnings)
              .reverse()
              .map((year) => (
                <Tab key={year} eventKey={year} title={year}>
                  <Table striped bordered>
                    <thead>
                      <tr>
                        <th>Kaupunki</th>
                        <th>Ajankohta</th>
                      </tr>
                    </thead>
                    <tbody>
                      {warnings[year].map((warning) => (
                        <tr key={warning.id}>
                          <td>{warning.city}</td>
                          <td>{format(warning.created_at)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Tab>
              ))}
          </Tabs>
        </Col>
      </Row>
    </Page>
  );
};

export default IndexPage;
